import { useEffect, useState } from "react";

export const useInteraction = (callback) => {
  const [isInteracted, setIsInteracted] = useState(false);

  useEffect(() => {
    const interactionHandler = () => {
      setIsInteracted(true);
    };

    document.addEventListener("click", interactionHandler);
    document.addEventListener("touchstart", interactionHandler);
    document.addEventListener("scroll", interactionHandler);
    document.addEventListener("keydown", interactionHandler);

    return () => {
      if (isInteracted) {
        document.removeEventListener("click", interactionHandler);
        document.removeEventListener("touchstart", interactionHandler);
        document.removeEventListener("scroll", interactionHandler);
        document.removeEventListener("keydown", interactionHandler);
      }
    };
  }, [isInteracted]);

  useEffect(() => {
    if (isInteracted) {
      callback && callback();
    }
  }, [isInteracted, callback]);
};
