import moment from "moment";

import { useInteraction } from "./hooks/useInteraction";
import { loadTawk } from "./utils/loadTawk";
import { loadWebwhiz } from "./utils/loadWebwhiz";

const Chat = () => {
  useInteraction(() => {
    const kyivTime = moment().tz("Europe/Kiev");
    const kyivCurrentHour = kyivTime.hour();

    if (kyivCurrentHour >= 8 && kyivCurrentHour < 19) {
      loadTawk();
    } else {
      loadWebwhiz();
    }
  });

  return null;
};

export default Chat;
