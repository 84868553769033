export const ROUTES = {
  MAIN: "/",
  HOME: "/home",
  TEST: "/test",
  ABOUT_US: "/about-us",
  BLOG: "/blog",
  BLOG_LOAN_FOR_STUDYING: "/blog/loan-for-studying",
  BLOG_LOAN_LEARN_LANGUAGE: "/blog/loan-learn-language",
  BLOG_CAREER_GROWTH_IN_IT: "/blog/career-growth-in-it",
  BLOG_FIVE_LIFEHACKS_FROM_CREDIT_EXPERT:
    "/blog/five-life-hacks-from-credit-expert",
  BLOG_RECEIVE_IN_UKRAINE: "/blog/receive-247-in-ukraine",
  BLOG_HOW_TO_APPLY_FOR_AUTOMATIC_LOAN_FROM_MFO:
    "/blog/how-apply-for-automatic-loan-from-mfo",
  STUDENT_LOAN: "/student-loan",
  FAMILY_LOAN: "/family-loan",
  PROMOACTIONNEWS: "/promoactionnews",
  FAQ: {
    FAQ: "/faq",
    COMMON: "/faq/common",
    PAYDAY_LOAN: "/faq/payday-loan",
    STUDY_LOAN: "/faq/study-loan",
    FAMILY_LOAN: "/faq/family-loan",
    REPAYMENT_AND_OVERDUE: "/faq/repayment-and-overdue",
    REJECT: "/faq/reject",
    ACCOUNT: "/faq/account",
    PROLONGATION: "/faq/prolongation",
    GUARANTOR: "/faq/guarantor",
    PROMOCODES: "/faq/promocodes",
    LEGAL: "/faq/legal",
  },
  LOYALTY_PROGRAM: "/loyalty-program",
  REVIEWS: "/reviews",
  PROLONGATION_GUIDE: "/prolongation-guide",
};
