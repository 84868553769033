import React, { useEffect, lazy, useState, Suspense } from "react";
import Favicon from "react-favicon";
import { RouterProvider } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import moment from "moment";

import "../lib/moment-timezone-with-data-10-year-range";
import router from "../routes/router";
import faicon_img from "shared/assets/icons/logo.png";

import "../styles/App.scss";
import "../styles/librariesCustomization.scss";
import "shared/assets/fonts/Montserrat/font-face.css";

const Cookies = lazy(() => import("modules/Cookies/Cookies"));

function App() {
  const [showCookies, setShowCookies] = useState(false);

  useEffect(() => {
    const timeWrite = localStorage.getItem("timeWrite");

    if (timeWrite) {
      const parseTimeWrite = moment(timeWrite)
        .add(1, "day")
        .format("DD.MM.YYYY HH:mm:ss");
      const now = moment(new Date()).format("DD.MM.YYYY HH:mm:ss");

      if (parseTimeWrite < now) {
        localStorage.removeItem("utm_campaign");
        localStorage.removeItem("utm_source");
        localStorage.removeItem("utm_medium");
        localStorage.removeItem("aff_sub");
        localStorage.removeItem("timeWrite");
      }
    }

    const agreeWithCookies = localStorage.getItem("agreeWithCookies");
    if (agreeWithCookies === null || agreeWithCookies === "false") {
      setShowCookies(true);
    }
  }, []);

  return (
    <>
      <Favicon url={faicon_img} />
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
      <ScrollToTop smooth />
      {showCookies && <Cookies />}
    </>
  );
}

export default App;
